<template>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <b>Dane partnera</b>
      <template v-if="partnerOperationResult">
        <template v-if="partnerOperationResult.isSuccess">
          <div class="alert alert-success" role="alert">
            {{partnerOperationResult.message}}
          </div>
        </template>
        <template v-else>
          <div class="alert alert-danger" role="alert">
            {{partnerOperationResult.message}}
          </div>
        </template>
      </template>

      <form @submit.prevent="saveChanges()">

        <template v-if="partnerData && partnerData.id">
          <div class="form-group row">
            <label for="id" class="col-sm-2 col-form-label">ID:</label>
            <div class="col-sm-10">
              <input class="form-control" id="id" v-model="partnerData.id" placeholder="edit me" readonly>
            </div>
          </div>
        </template>

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label">Nazwa:</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="name" v-model="partnerData.name">
          </div>
        </div>

        <div class="form-group row">
          <label for="country" class="col-sm-2 col-form-label">Państwo:</label>
          <div class="col-sm-10">
            <select name="country" id="country" v-model="partnerData.countryId">
              <template v-for="country in countryList" v-bind:key="country.id">
                <option v-bind:value="country.id">{{country.name}}</option>
              </template>
            </select>
          </div>

          <div class="form-group row">
            <label for="website" class="col-sm-2 col-form-label">Strona www:</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="website" v-model="partnerData.website">
            </div>
          </div>

          <div class="form-group row">
            <label for="partnerType" class="col-sm-2 col-form-label">Typ partnera:</label>
            <div class="col-sm-10">

              <select name="partnerType" id="partnerType" @change="changePartnerTypeInSelect($event)" v-model="partnerData.typeId">
                <template v-for="partnerType in partnerTypeList" v-bind:key="partnerType.id">
                  <option v-bind:value="partnerType.id" v-on:click="partnerData.partnerTypeId = partnerType.id">
                    {{partnerType.type}}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <template v-if="selectedPartnerType === 'deal'">
            <div class="form-group row">
              <label for="currency" class="col-sm-2 col-form-label">Waluta:</label>
              <div class="col-sm-10">
                <select name="currency" id="currency" v-model="partnerData.currencyId">
                  <template v-for="currency in currencyList" v-bind:key="currency.id">
                    <option v-bind:value="currency.id" selected>{{currency.name}}</option>
                  </template>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label for="commission" class="col-sm-2 col-form-label">Prowizja:</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="commission" v-model="partnerData.commission">
              </div>
            </div>
          </template>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div>
    <div class="col-md-3"></div>
  </div>
</template>

<script>
import SalesPackagePartnerService from '../../../services/admin/SalesPackage/salesPackagePartnerService';
import CountryService from '../../../services/countryService';
import { useRoute } from 'vue-router';
import CurrencyService from '../../../services/currencySevice';

export default {
  name: 'Partner',
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.partnerId = toParams.id ?? null;

        if (this.partnerId) {
          this.displayPartnerData();
          this.provideDataToFormSelects();
          this.changePartnerTypeInSelect();
        } else {
          this.resetPartnerData();
          this.resetResultMessage();
        }
      }
    );
  },
  mounted() {
    const route = useRoute();
    this.partnerId = route.params.id;
    this.initializeForm();
  },
  data() {
    return {
      partnerId: null,
      partnerData: {
        id: null,
        name: null,
        website: null,
        typeId: null,
        currencyId: null,
        commission: null
      },
      countryList: [],
      currencyList: [],
      partnerTypeList: [],
      selectedPartnerType: null,
      partnerOperationResult: null
    };
  },
  methods: {
    async initializeForm() {
      const routeParams = this.$route.params;
      this.partnerId = routeParams.id ?? null;

      if (this.partnerId) {
        this.partnerId = routeParams.id;
        await this.displayPartnerData();
      }

      await this.provideDataToFormSelects();
      await this.changePartnerTypeInSelect();
    },
    async provideDataToFormSelects() {
      const responseCountry = await CountryService.getCountryList();
      this.countryList = responseCountry.data.countries.items;

      const responseCurrency = await CurrencyService.getCurrencyList();
      this.currencyList = responseCurrency.data.currencies.items;

      const responsePartnerType = await SalesPackagePartnerService.getPartnerTypeList();
      this.partnerTypeList = responsePartnerType.data.partnerTypes.items;
    },
    async displayPartnerData() {
      const response = await SalesPackagePartnerService.getPartnerData(this.partnerId);
      const partner = response.data.partners.items[0];

      this.partnerData.id = partner.id;
      this.partnerData.name = partner.name;
      this.partnerData.countryId = partner.country ? partner.country.id : null;
      this.partnerData.website = partner.website;
      this.partnerData.typeId = partner.type.id;
      this.partnerData.currencyId = partner.currency ? partner.currency.id : null;
      this.partnerData.commission = partner.commission;

      for (const partnerType of this.partnerTypeList) {
        if (partnerType.id === this.partnerData.partnerTypeId) {
          this.selectedPartnerType = partnerType.type;
        }
      }
    },
    resetPartnerData() {
      this.partnerData.id = null;
      this.partnerData.name = null;
      this.partnerData.typeId = null;
      this.partnerData.website = null;
      this.partnerData.countryId = null;
      this.partnerData.commission = null;
      this.partnerData.currencyId = null;
    },
    resetResultMessage() {
      this.partnerOperationResult = null;
    },
    async saveChanges() {
      const response = await SalesPackagePartnerService.addPartner(
        this.partnerData.name,
        this.partnerData.typeId,
        this.partnerData.website,
        this.partnerData.countryId,
        this.partnerData.commission,
        this.partnerData.currencyId
      );

      this.partnerOperationResult = response.data.partners;
      const partnerId = this.partnerOperationResult.items[0].id;

      await this.$router.push('/admin/partner/' + partnerId);
    },
    changePartnerTypeInSelect() {
      const selectedPartnerTypeId = this.partnerData.typeId;

      for (const partnerType of this.partnerTypeList) {
        if (selectedPartnerTypeId === partnerType.id) {
          this.selectedPartnerType = partnerType.type;
        }
      }
    }
  }
};
</script>

<style scoped>

</style>
