import requestService from './requestService';

export class CountryService {
  static async getCountryList() {
    const path = '/rest/country/countries';

    return requestService.makeGetRequest(path);
  }
}

const countryService = CountryService;
export default countryService;
