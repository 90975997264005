import requestService from './requestService';

export class CurrencyService {
  static async getCurrencyList() {
    const path = '/rest/currency/currencies';

    return requestService.makeGetRequest(path);
  }
}

const currencyService = CurrencyService;
export default currencyService;
